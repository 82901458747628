import * as React from "react";
import {
  Box,
  Button,
  ChakraProvider,
  Flex,
  Heading,
  Image,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  theme,
  Tr,
} from "@chakra-ui/react";

import logoLingo from "./assets/logoLingo.png";

const pictures = [
  {
    filename: "DJI_0492",
    user: "groupe",
  },
  {
    filename: "DJI_0493",
    user: "groupe",
  },
  {
    filename: "DJI_0494",
    user: "groupe",
  },
  {
    filename: "DJI_0495",
    user: "groupe",
  },
  {
    filename: "DJI_0518",
    user: "Olivier + Ricos",
  },
  {
    filename: "DJI_0528",
    user: "V1",
  },
  {
    filename: "DJI_0529",
    user: "V2",
  },
  {
    filename: "DJI_0530",
    user: "grp 1",
  },
  {
    filename: "DJI_0531",
    user: "grp 2",
  },
  {
    filename: "DJI_0532",
    user: "grp 3",
  },
  {
    filename: "DJI_0533",
    user: "grp 4",
  },
  {
    filename: "DJI_0534",
    user: "grp 5",
  },
];

const videos = [
  {
    filename: "DJI_0496",
    user: "Maxime",
  },
  {
    filename: "DJI_0497",
    user: "Ricos",
  },
  {
    filename: "DJI_0498",
    user: "Olivier",
  },
  {
    filename: "DJI_0499",
    user: "Wilfried",
  },
  {
    filename: "DJI_0500",
    user: "Sandra",
  },
  {
    filename: "DJI_0501",
    user: "Maxime",
  },
  {
    filename: "DJI_0502",
    user: "Nicolas",
  },
  {
    filename: "DJI_0503",
    user: "Vincent",
  },
  {
    filename: "DJI_0504",
    user: "Olivier",
  },
  {
    filename: "DJI_0505",
    user: "Victoria",
  },
  {
    filename: "DJI_0506",
    user: "Maxime",
  },
  {
    filename: "DJI_0507",
    user: "Salan",
  },
  {
    filename: "DJI_0508",
    user: "Vincent",
  },
  {
    filename: "DJI_0509",
    user: "Didier",
  },
  {
    filename: "DJI_0510",
    user: "Olivier",
  },
  {
    filename: "DJI_0511",
    user: "Lionel",
  },
  {
    filename: "DJI_0512",
    user: "Victoria",
  },
  {
    filename: "DJI_0513",
    user: "Sandra",
  },
  {
    filename: "DJI_0514",
    user: "Andrea",
  },
  {
    filename: "DJI_0515",
    user: "Philippe",
  },
  {
    filename: "DJI_0516",
    user: "Nico",
  },
  {
    filename: "DJI_0517",
    user: "Mario",
  },
  {
    filename: "DJI_0519",
    user: "Lionel / Victoria",
  },
  {
    filename: "DJI_0520",
    user: "Salan",
  },
  {
    filename: "DJI_0521",
    user: "Valda",
  },
  {
    filename: "DJI_0522",
    user: "Philippe",
  },
  {
    filename: "DJI_0523",
    user: "Sandra",
  },
  {
    filename: "DJI_0524",
    user: "de la merde",
  },
  {
    filename: "DJI_0525",
    user: "Salan",
  },
  {
    filename: "DJI_0526",
    user: "Maxime",
  },
  {
    filename: "DJI_0527",
    user: "V comme victoire",
  },
  {
    filename: "DJI_0535",
    user: "grp video",
  },
  {
    filename: "DJI_0536",
    user: "Philippe",
  },
  {
    filename: "DJI_0537",
    user: "Valda",
  },
  {
    filename: "DJI_0538",
    user: "Wilfried",
  },
  {
    filename: "DJI_0539",
    user: "Vincent",
  },
  {
    filename: "DJI_0540",
    user: "Nico",
  },
  {
    filename: "DJI_0542",
    user: "Sandra",
  },
  {
    filename: "DJI_0543",
    user: "Ricos",
  },
  {
    filename: "DJI_0544",
    user: "Philippe",
  },
  {
    filename: "DJI_0545",
    user: "Wack",
  },
  {
    filename: "DJI_0546",
    user: "Ricos",
  },
];

const cloudFrontUrl = "https://dqiv7w7t2zdx2.cloudfront.net/";

export const App = () => (
  <ChakraProvider theme={theme}>
    <Box
      w="100%"
      p={4}
      h="80px"
      boxShadow={"base"}
      mb="30px"
      position="sticky"
      top="0"
      zIndex="10000000"
      backgroundColor="white"
    >
      <Flex
        sx={{ width: "80%", maxWidth: "1600px", margin: "auto" }}
        justifyContent="start"
        alignContent="space-between"
        alignItems="center"
      >
        <Image src={logoLingo} alt="logo_tsv_lingo" boxSize="50px" />
        <Heading ml={10} textAlign="center">
          {" "}
          Tsv Lingolsheim à Selongey Juin 2021
        </Heading>
      </Flex>
    </Box>

    <Flex
      sx={{ width: "80%", maxWidth: "1600px", margin: "auto" }}
      justifyContent="center"
      alignContent="space-between"
      alignItems="start"
    >
      <Box>
        <Heading textAlign="center">Photos</Heading>
        <Table w="45%">
          <Thead>
            <Tr>
              <Th>Index</Th>
              <Th> Personne(s) présente(s)</Th>
              <Th> Télécharger DNG (Raw)</Th>
              <Th> Télécharger JPG</Th>
            </Tr>
          </Thead>
          <Tbody>
            {pictures.map((pic, index) => (
              <Tr>
                <Td>{index}</Td>
                <Td>{pic.user}</Td>
                <Td>
                  <Button
                    colorScheme="blue"
                    onClick={() => {
                      window
                        ?.open(`${cloudFrontUrl}${pic.filename}.DNG`, "_blank")
                        ?.focus();
                    }}
                  >
                    Télécharger DNG
                  </Button>
                </Td>
                <Td>
                  <Button
                    colorScheme="blue"
                    onClick={() => {
                      window
                        ?.open(`${cloudFrontUrl}${pic.filename}.JPG`, "_blank")
                        ?.focus();
                    }}
                  >
                    Télécharger JPG
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      <Box>
        <Heading textAlign="center">Videos</Heading>
        <Table w="45%">
          <Thead>
            <Tr>
              <Th>Index</Th>
              <Th> Personne(s) présente(s)</Th>
              <Th> Télécharger MP4</Th>
            </Tr>
          </Thead>
          <Tbody>
            {videos.map((video, index) => (
              <Tr>
                <Td>{index}</Td>
                <Td>{video.user}</Td>
                <Td>
                  <Button
                    colorScheme="blue"
                    onClick={() => {
                      window
                        ?.open(
                          `${cloudFrontUrl}${video.filename}.MP4`,
                          "_blank"
                        )
                        ?.focus();
                    }}
                  >
                    Télécharger MP4
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Flex>
  </ChakraProvider>
);
